import React from 'react';
import globalTexts from '../../resources/texts';
import gStyles from '../../App.module.css';
import pStyles from './Home.module.css'
import '../../resources/sprites.css';
import * as icon from '../../util/IconConstants'
import * as size from '../../util/SizeConstants'
import texts from '../../resources/texts';

// Home Page Header - must be stateless


/**
 * @typedef {Object} Props
 * @property { ()=>void} onClickGetApps
 */

/**@param {Props} props */

export const HomeHeader = (props) => (
    <>
        <nav className={[gStyles.rowNowrapFlex, gStyles.navBar].join(' ')} >

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{globalTexts.appTitle}</span>
            
            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >


                {/* apps list */}
                <span 
                    onClick={props.onClickGetApps}
                    style={{ paddingRight: size.NORMAL_SPACER }}
                    title={texts.appsTip}>
                    <button className={icon.APPS_LIST + " " + gStyles.btnIcon}></button>
                    <span className={pStyles.iconText}>{globalTexts.getApps}</span>
                </span>

            </div>
        </nav>


    </>
);


