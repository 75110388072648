import React from 'react'
import gTexts from '../../resources/texts'
import gStyles from '../../App.module.css';
import styles from './ContactUs.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import { goBack } from '../../navigation/PageManager'

export default class ContactUs extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{gTexts.contactUsTitle}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: 
                        `
                        <p>
                        You can give us feedback, comments, sugggestions, ask question, or report issues about our product.
                        
                         
                        <p>
                        You may get in touch with us through the following,
                        <div style='padding-left:20px'>
                        <p>
                        Phone: +1 778 952 3068
                        <p>
                        Email: <a href="mailto:info@lunaresoft.com" target="_top">info@lunaresoft.com</a>
                        <p>
                        Address: 7888 Saba Road, Richmond BC Canada V6Y 0A2
                        </div>
                        `
                    }}
                >
                </main>
            </>




        )
    }

}