import React from 'react';
import gTexts from '../../resources/texts'
import texts from './HomeTexts';
import pStyles from './Home.module.css';
import gStyles from '../../App.module.css';
import '../../resources/sprites.css';

import bgImage from '../../resources/computer-hand.png'

export const Section = {
    CREATE: 'create',
    SEARCH: 'search',
    VIEW: 'view',
    SETUP: 'setup',
    INFO: 'info'
}

// Home Page View - must be stateless

/**
 * @typedef {Object} Props
 * @property { ()=>void} onClickGetApps
 * @property { ()=>void} onClickUsage
 * @property { ()=>void} onClickHowItWork
 * @property { ()=>void} onClickFAQ
 * @property { ()=>void} onClickAboutUs
 * @property { ()=>void} onClickContactUs
 * @property { ()=>void} onClickArticles
 */



/**@param {Props} props */
export const HomeView = (props) => (
    <main className={[gStyles.colFlex, pStyles.main].join(' ')}>

        {/* What is MyBrainMate */}
        <div className={pStyles.imgContainer}>
            <img src={bgImage} alt="hand on computer" className={pStyles.imgResponsive}></img>
            <div className={pStyles.imgContent}>
                <div className={pStyles.imgText}
                    dangerouslySetInnerHTML={{__html:
                    `
                    <div style='margin-bottom:20px'>
                        <span style='font-weight:700'>MyBrainMate</span> is your Personal Information Manager
                    </div>

                    <div style='margin-bottom:10px'>
                    MyBrainMate is a productivity tool to manage important resources in your life such as time, energy, money, knowledge, and more.
                    </div>
                    <div>
                    When you’re productive, you get more out of life, you’re efficient, you accomplish more, you’re focused, you’re organized – you simply become better.
                    </div>
                    `
                    }}
                >
                </div>
            </div>

        </div>

        <div style={{marginTop:'30px'}}
            dangerouslySetInnerHTML={{__html:
            `
            <div style='font-weight:600'>
                Your personal organizer
            </div>

            <div style='margin:10px 0 20px'>
                MyBrainMate helps you get organized, remember appointments, and plan projects
            </div>

            <div style='font-weight:600'>
                Your knowledge database
            </div>

            <div style='margin:10px 0 20px'>
                MyBrainMate helps you build your knowledge stack
            </div>
            
            <div style='font-weight:600'>
                Your brain companion
            </div>

            <div style='margin:10px 0 20px'>
                MyBrainMate complements your brain, it's an extension of your brain
            </div>                    
            `
            }}
        >
        </div>

        {/* dummy line so the next line will style properly ? */}
        <hr className={pStyles.lineRule} style={{visibility:'hidden'}}></hr>

        {/* video 1 */}
        <div className={pStyles.sectionHeader}>
            {texts.video1Header}
        </div>
        <hr className={pStyles.lineRule}></hr>

        <div dangerouslySetInnerHTML={{__html:
            `
            <div style='margin:20px 0 20px'>
                MyBrainMate is a software that assists you with your brain and thought functions. 
                <div style='margin-top:10px'>
                To learn more, please play the video below.
                </div>
            </div>
            `
            }}
        >
        </div>        

        <div className={pStyles.videoContainer}>
            <iframe 
                className={pStyles.videoContent} 
                src="https://www.youtube.com/embed/dr90lZM3wd0?rel=0&amp;showinfo=0" 
                allowFullScreen={true}
                title="MyBrainMate"
            ></iframe>
        </div>


        {/* video 2 */}
        <div className={pStyles.sectionHeader}>
            {texts.video2Header}
        </div>
        <hr className={pStyles.lineRule}></hr>

        <div dangerouslySetInnerHTML={{__html:
            `
            <div style='margin:20px 0 20px'>
                MyBrainMate is a personal information management (PIM) software that lets you build your knowledge database. 
                <div style='margin-top:10px'>
                It's a productivity tool used for organizing, learning, and planning.
                </div>
                <div style='margin-top:10px'>
                Please play the video below to learn more.
                </div>
            </div>
            `
            }}
        >
        </div>        

        <div className={pStyles.videoContainer}>
            <iframe 
                className={pStyles.videoContent} 
                src="https://www.youtube.com/embed/yskrmhTezr8?rel=0&amp;showinfo=0" 
                allowFullScreen={true}
                title="MyBrainMate"
            ></iframe>
        </div>


        {/* usage */}
        <div className={pStyles.sectionHeader}>
            {texts.useCaseHeader}
        </div>
        <hr className={pStyles.lineRule}></hr>

        <div dangerouslySetInnerHTML={{__html:
            `
            <p>
            You can use MyBrainMate for the following,

            <div style='font-weight:600'>
            Build and maintain personal knowledge database
            </div>
            <p>
            Whether you're a student, professional or business owner, you have a specific set of knowledge you want to grow, keep, and maintain. This knowledge helps you gain the advantage in your chosen field. As they say, knowledge is power - only if you gather them.

            <div style='font-weight:600'>
            Learning tool
            </div>
            <p>
            When you're picking up new set of skills or just interested on certain topics, you can learn quicker and better with a mind mapping tool and MyBrainMate is just that tool. You can organize and visualize information by representing them as connected cells just like the way your brain works.
            
            <div style='font-weight:600'>
            Idea development and brainstorming
            </div>
            <p>
            Sometimes just by writing ideas as cells, you can develop other ideas by connecting them together. This act can trigger new ideas. You continue this process until you reach an acceptable solution to your problem. And you can use MyBrainMate as a tool to make the connections between cells.

            <div style='font-weight:600'>
            Instructions
            </div>
            <p>
            There are things you do in step-by-step manner. You don't need to memorize them, just keep them handy. You can store these information in MyBrainMate for quick retrieval. For example, cooking instructions or machine setup instructions.

            <div style='font-weight:600'>
            List builder
            </div>
            <p>
            There are information that you want to treat as a single unit and list is one of them. For example, your list of prescriptions, favorite books, top ten list, or even grocery list. You may want to write them once into MyBrainMate and retrieve them anytime, anywhere you need them.

            <div style='font-weight:600'>
            Notes
            </div>
            <p>
            You may think that you only need to remember long-term information. Much more important are transient information because your brain has a very limited short-term memory (both time and capacity). Whether it's trivial or not, store it in MyBrainMate. You then decide later if you want to keep it or not.

            <div style='font-weight:600'>
            Compile lessons in life
            </div>
            <p>
            As humans, we all make mistakes. A flipside to mistakes is that there are important lessons you learn from them. And you don't want to make the same mistakes over again. MyBrainMate can help you compile these important lessons for later review, or for sharing with people you care.

            <p>
            As you can see, there's no limit to what you can do with MyBrainMate, it all depends on your own situation, creativity, and imagination.
            `
        }}
        >
        </div>

        {/* call to action */}
        <div className={[pStyles.ctaSection].join(' ')}>
            <span className={pStyles.ctaText}>{texts.cta}</span>
            <div style={{paddingTop:'10px'}} onClick={props.onClickGetApps}>
                <span className={[gStyles.btnDefault, pStyles.ctaButton].join(' ')}>{texts.ctaButtonText}</span>
            </div>
        </div>

{/* hide for now */}
<div style={{display:'none'}}>  
        {/* how it works */}
        <div>
            <header className={[pStyles.sectionHeader].join(' ')} onClick={props.onClickHowItWork}>
                {`How it works`}
            </header>
        </div>

        {/* FAQ */}
        <div>
            <header className={[pStyles.sectionHeader].join(' ')} onClick={props.onClickFAQ}>
                {`FAQ`}
            </header>
        </div>

        {/* Articles */}
        <div>
            <header className={[pStyles.sectionHeader].join(' ')} onClick={props.onClickFAQ}>
                {`Articles`}
            </header>
        </div>
</div>



        <div className={[gStyles.rowFlex, pStyles.footer].join(' ')}>
            <hr style={{border:'.8px solid gray', width:'100%'}}></hr>
            <div 
                onClick={props.onClickAboutUs}
                style={{cursor:'pointer'}}
            >{texts.aboutUs}</div>
            <div 
                onClick={props.onClickContactUs} 
                style={{paddingLeft: '20px', cursor:'pointer'}}
            >{texts.contactUs}</div>
            <div className={gStyles.splitRight}>&copy; {gTexts.copyRight}</div>
        </div>

    </main>
);


