import React from 'react'
import gTexts from '../../resources/texts'
import gStyles from '../../App.module.css';
import styles from './AboutUs.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import { goBack } from '../../navigation/PageManager'

export default class AboutUs extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{gTexts.aboutUsTitle}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: 
                        `
                        <p style='padding-top:20px; font-weight:600; font-size:20px'>
                        Our Company
                        <p>
                        LunareSoft is the company behind MyBrainMate.
                        <p>
                        Our company is based in Vancouver, Canada.
                        <p>
                        At LunareSoft, we specialize in productivity tools such as task management, time management, and resource management.
                        <p>
                        We also provide IT Consulting Services and custom software development.
                        <p>
                        We have 20 years of experience in Architecture, Design, and Software Development.
                        
                        
                        <p style='padding-top:20px; font-weight:600; font-size:20px'>
                        Our Vision
                        <p>
                        We aim to be the industry expert in the field of personal and collaborative productivity tools.

                        <p style='padding-top:20px; font-weight:600; font-size:20px'>
                        Our Mission
                        <p>
                        At LunareSoft, our mission is to make you the most productive person you can become.
                        <p>
                        With our software tools, we can make you -- a 'better' you.
                        
                        <p style='padding-top:20px; font-weight:600; font-size:20px'>
                        Our Methodologies
                        <p>
                        Our development methodology is based on Agile principles. We're allergic to complicated processes and overly complex plans.
                        <p>
                        We like to do things just-enough and just-in-time.
                        <p>
                        Change? We embrace change instead of avoiding it! We like to make incremental changes -- baby steps, continuous improvement!
                        
                        <p style='padding-top:20px; font-weight:600; font-size:20px'>
                        Our Philosophy
                        <p>
                        We live by the KISS (Keep it Short and Simple) philosophy.
                        <p>
                        We simplify our processes down to the core and only the fundamentals. We like to keep it lean and lightweight.
                        <p>
                        And lastly, we enjoy life! We live in the present but mindful of the future and learn from the past!                                      
                        `
                    }}
                >
                </main>
            </>




        )
    }

}