import React from 'react';
import {HomeHeader} from './HomeHeader';
import {HomeView} from './HomeView';
import * as PageManager from '../../navigation/PageManager';
import * as page from '../../util/PageConstants';
import { goToPage } from '../../navigation/PageManager';
import { Utils } from '../../util/Utils';


/**
 * Home Page
 * - container component
 */
export default class Home extends React.Component {

    // initialize expanded sections
    state = {
        expanded: {
            create: true,
            search: true,
            view: true,
            setup: false,
            info: false,
        }
    }

    componentDidMount() {
        // log ('Home did mount');
    }
    
    render() {
        return (
            <>
            <HomeHeader 
                onClickGetApps = {this.onClickGetApps}
            /> 
            <HomeView
                onClickGetApps      = {this.onClickGetApps}
                onClickUsage        = {this.onClickUsage}
                onClickHowItWork    = {this.onClickHowItWork}
                onClickFAQ          = {this.onClickFAQ}
                onClickAboutUs      = {this.onClickAboutUs}
                onClickContactUs    = {this.onClickContactUs}
                onClickArticles     = {this.onClickArticles}
            />
            </>
        )
    }

    onClickGetApps = () => {
        window.location.href = page.APPS_LIST;
    }
    
    onClickUsage = () => {
        PageManager.goToPage(page.USAGE);
    }
    onClickHowItWork = () => {
        PageManager.goToPage(page.HOW_IT_WORK);
    }
    onClickFAQ = () => {
        PageManager.goToPage(page.FAQ);
    }
    onClickAboutUs = () => {
        goToPage(page.ABOUT_US);
    }
    onClickContactUs = () => {
        PageManager.goToPage(page.CONTACT_US);
    }
    onClickArticles = () => {
        PageManager.goToPage(page.ARTICLES);
    }

}

