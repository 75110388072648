export default  {
    useCaseHeader   : 'Use Cases',
    video1Header    : 'Let MyBrainMate do the heavylifting, Let your brain do the thinking!',
    video2Header    : 'Organize your thoughts, Organize your life!',

    createHeader:   'Create',
    searchHeader:   'Search',
    viewHeader:     'Views',
    setupHeader:    'Setup',
    infoHeader:     'Info',
    iWantTo:        '',
    createInfo:     'Create New Info',
    searchInfo:     'Search My Info',
    iWantToView:    '',
    byCategory:     'View by Category',
    byExpertise:     'View by Expertise',
    byImportance:   'View by Importance',
    byLabel:        'View by Label',
    iWantToSetup:   '',
    infoCategories: 'Info Categories',
    infoLabels:     'Info Labels',
    iWantToKnow:    '',
    about:          'About MyBrainMate',
    howToUse:       'How to use MyBrainMate Info',
    tipsTricks:     'Tips & Tricks of MyBrainMate Info',
    aboutUs         : 'About Us',
    contactUs       : 'Contact Us',
    cta             : 'So are you ready to become more productive, efficient, and organized?',
    ctaButtonText   : 'Yes, go to Apps!',
}