/**
 * page ids used for navigation
 */

export const HOME               = 'HOME';
export const EDIT_TASK          = 'EDIT_TASK';
export const SEARCH             = 'SEARCH';
export const VIEW_BY_CATEGORY   = 'VIEW_BY_CATEGORY';
export const VIEW_BY_HOT_TASKS  = 'VIEW_BY_HOT_TASKS';
export const VIEW_BY_DATE       = 'VIEW_BY_DATE';
export const VIEW_BY_IMPORTANCE = 'VIEW_BY_IMPORTANCE';
export const VIEW_BY_PRIORITY   = 'VIEW_BY_PRIORITY';
export const VIEW_BY_STATUS     = 'VIEW_BY_STATUS';
export const VIEW_BY_LABEL      = 'VIEW_BY_LABEL';
export const EDIT_CATEGORY      = 'EDIT_CATEGORY';
export const SETUP_CATEGORY     = 'SETUP_CATEGORY';
export const EDIT_LABEL         = 'EDIT_LABEL';
export const SETUP_LABEL        = 'SETUP_LABEL';
export const SEARCH_ICON        = 'SEARCH_ICON';
export const SELECT_COLOR       = 'SELECT_COLOR';
export const FILTER             = 'FILTER';
export const SYSTEM             = 'SYSTEM';
export const ABOUT              = 'ABOUT';
export const HOW_TO             = 'HOW_TO';
export const TIPS_TRICKS        = 'TIPS_TRICKS';
export const ERROR              = 'ERROR';

export const USAGE              = 'USAGE';
export const HOW_IT_WORK        = 'HOW_IT_WORK';
export const FAQ                = 'FAQ';
export const ARTICLES           = 'ARTICLES';
export const ABOUT_US           = 'ABOUT_US';
export const CONTACT_US         = 'CONTACT_US';

// redirect to apps url
export const APPS_LIST           = 'http://apps.mybrainmate.com';


